import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import show1 from '../assest/des1.jpg'
import show2 from '../assest/des3.jpg'
import show3 from '../assest/des2.jpg'
import show4 from '../assest/des4.jpg'
import show5 from '../assest/des5.jpg'
import show6 from '../assest/des6.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { useTranslation } from 'react-i18next'

function Certificates () {
    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);

    const { t, i18n } = useTranslation();

    const services_en = services.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "keyword": ser_en.keyword_en,
                "img_service": ser_en.img_service,
                "whats_service": ser_en.whats_service_en
            })
        }
        return ser_en;
    })

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch show
        dispatch(fetchServices());
    }, [])

    return (
        <section className='certificates' id='services'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=201227210814&text=أهلا, معالي النقيب محمد خليل, اريد التحدث معك بخصوص استشارة قانونية !" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>{i18n.language == "ar" ? "الخدمات" : "Services"}</span>
                    <p>{i18n.language == "ar" ? "الخدمات" : "Services"}</p>
                </h2>
                <div className="cards">
                    {services_en.map((card) => {
                        return (
                            <Link to={`/service/${card.id}`} data-aos="zoom-out-up" className="card" key={card.id}>
                                <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img_service}`} alt="" />
                                <h4 style={{ paddingTop: "1rem" }}>{card.keyword}</h4>
                            </Link>
                        )
                    })}
                </div>
                {/* <Link className="btn two" to={`/services`}>
                    <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.51038 32.6729C-0.990382 34.1837 -0.232333 37.9945 2.65612 38.4335C4.97848 38.7866 6.09439 41.4804 4.70164 43.3722C2.96938 45.7248 5.12803 48.9555 7.9646 48.2555C10.245 47.6925 12.3074 49.7548 11.7445 52.0354C11.0445 54.8721 14.2752 57.0306 16.6278 55.2984C18.5196 53.9057 21.2139 55.0216 21.5665 57.3439C22.0054 60.2322 25.8161 60.9904 27.3271 58.4896C28.5419 56.479 31.4581 56.479 32.6729 58.4896C34.1837 60.9904 37.9945 60.2323 38.4335 57.3439C38.7866 55.0215 41.4804 53.9056 43.3722 55.2984C45.7248 57.0306 48.9555 54.872 48.2555 52.0354C47.6925 49.755 49.7548 47.6925 52.0354 48.2555C54.872 48.9555 57.0306 45.7248 55.2984 43.3722C53.9057 41.4804 55.0216 38.7861 57.3439 38.4335C60.2322 37.9946 60.9904 34.1839 58.4896 32.6729C56.479 31.4581 56.479 28.5419 58.4896 27.3271C60.9904 25.8163 60.2323 22.0055 57.3439 21.5665C55.0215 21.2134 53.9056 18.5196 55.2984 16.6278C57.0306 14.2752 54.872 11.0445 52.0354 11.7445C49.7549 12.3075 47.6925 10.2452 48.2555 7.9646C48.9555 5.12795 45.7248 2.96938 43.3722 4.70164C41.4804 6.09426 38.7861 4.9784 38.4335 2.65612C37.9946 -0.23224 34.1839 -0.990422 32.6729 1.51038C31.4581 3.52095 28.5419 3.52095 27.3271 1.51038C25.8163 -0.990382 22.0055 -0.232333 21.5665 2.65612C21.2134 4.97848 18.5196 6.09439 16.6278 4.70164C14.2752 2.96938 11.0445 5.12803 11.7445 7.9646C12.3075 10.245 10.2452 12.3074 7.9646 11.7445C5.12795 11.0445 2.96938 14.2752 4.70164 16.6278C6.09426 18.5196 4.9784 21.2139 2.65612 21.5665C-0.232239 22.0054 -0.990423 25.8161 1.51038 27.3271C3.52095 28.5419 3.52095 31.4581 1.51038 32.6729Z"></path>
                    </svg>

                    <div className="btn-content">
                        عرض كل الخدمات
                        <span>
                            <i className="fa fa-arrow-up"></i>
                        </span>
                    </div>
                </Link> */}
            </div>
        </section>
    )
}

export default Certificates
